.Text {
   display: flex;
}

.section1-comp5 {
   display: flex;
}

/* .open-name{
   cursor: pointer;
   color: #b33030;
   height: 15px;
   font-weight: bold;
   font-family: poppins;
} */

.section1-comp5 hr {
   margin-left: 8px;
   width: 100%;
   /* max-width: 100%; */
   border-top: 2px solid;
   height: 0px;
   margin-top: 14px;
   color: #ecebeb;
}



.css-6od3lo-MuiChip-label {
   padding: 0px 10px !important;

}

.chipbtn {
   border-radius: 8px !important;
   height: 22px !important;
} 

/* .css-w66kx-MuiChip-root {
   border-radius: 5px !important;
   height: 22px !important;
} */

.open-btn {
   text-align: center !important;
}
.onclick-hover:hover{
background-color:#f5f5f5;

}
.onclick-hover{
   padding:8px;
}

@media only screen and (min-width:444px) and (max-width: 900px) {
   .MuiGrid-root {
      justify-content: center !important;
   }
}
.parent-comp5 a{
 display:flex;
 justify-content: flex-end;
 font-size: 13px;
 cursor : pointer;
 color: #5c8acb;
 height: 18px;
 font-weight:600px !important;
 font-family: Poppins !important;
 text-decoration: none;
 
}