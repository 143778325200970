.applogo {
    width: 70%;
}

/* p {
    background-color: blue;
    color: red;
      } /

      .dark-mode {
        background-color:rgb(31, 34, 36);
      }
      .dark-mode p{
        color: white;
        / background-color:black; */
      
      .dark-mode .polygon-img{
        background-color: rgb(31,34,36);
      }

      .dark-mode .info-heading p{
        color: white;
      }

  .dark-mode .card-div p{
    color: white;
  }

  .dark-mode .gridcomp {
    background-color:black !important;
  }
  .App {
    text-align: center;
  }
  
  .App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }

  .dark-mode .card-div{
    background-color:black !important;
  }

  .dark-mode .seeall-btn button {
    background-color:#322727 !important;
    color: white;
  }

  .dark-mode .ratingheading h1 {
    color: white;
  }
  .dark-mode .santa-logo-name{
    color:white;
  }

  .dark-mode .new{
    color: white;
  }

  .dark-mode .card-div h1{
    color: white;
  }

  .dark-mode .title2{
    color: white;
  }

  .dark-mode .last-grid-page{
    background-color:black !important;
  }
  .dark-mode .last-grid-flex h1{
    color: white;
  }

  .dark-mode .last-grid-flex p {
    color: white;
  }

  .dark-mode .radio-twit-icons{
    /* background-color: black; */
    color: white;
  }

  .dark-mode .airnfts h6{
    color: white;
  }

  .dark-mode .icon-texts{
    background-color: #2a2626fc !important;
  }

  .dark-mode .title1{
    color: white;
  }
/* @media only screen and (max-width: 920px){
    .MuiDrawer-root {
        display:none;

    }
} */

 .button:hover {
   background-color:black !important;

   border-radius: 8px; 
   width:200px !important;
 
} 
/* 
.btn:hover {
    color: white;
} */
.btn.active{
    color:white;
}
.home {
    color: 'black';
    font-size:20px;
    margin-left:-3px;
}

.side-bar-btn {
    margin-left: 8px !important;
    margin-top:10px !important;

}
.side-bar-btn:hover img{
mix-blend-mode: luminosity !important;
-webkit-filter: invert(90%) !important;
filter: invert(300%) !important;
}
/* .dark-mode .side-bar-btn:hover img{
    mix-blend-mode: luminosity !important;
-webkit-filter: invert(90%) !important;
filter: invert(300%) !important;
}
.dark-mode .home{
    mix-blend-mode: luminosity !important;
    -webkit-filter: invert(90%) !important;
    filter: invert(300%) !important;
} */

 .side-bar-btn:hover .darkmodelogo {

mix-blend-mode: plus-lighter ;
-webkit-filter: invert(90%);
filter: invert(100%);
} 
 .side-bar-btn:hover .home{
    mix-blend-mode: plus-lighter;
    -webkit-filter: invert(90%);
    filter: invert(100%);
}
.side-bar-btn.active .home{
    mix-blend-mode: plus-lighter;
    -webkit-filter: invert(90%);
    filter: invert(100%);
} 
 .side-bar-btn:hover .login{
    mix-blend-mode: plus-lighter;
    -webkit-filter: invert(90%);
    filter: invert(100%);
}
.side-bar-btn.active .login{
    mix-blend-mode: plus-lighter;
    -webkit-filter: invert(90%);
    filter: invert(100%);
} 

.side-bar-btn.active img {

mix-blend-mode: plus-lighter;
-webkit-filter: invert(90%);
filter: invert(100%);
}
.side-bar-btn:hover .right-arrow-icon{
    mix-blend-mode: plus-lighter;
    -webkit-filter: invert(90%);
    filter: invert(100%);
}
.side-bar-btn.active .right-arrow-icon{
    mix-blend-mode: plus-lighter;
    -webkit-filter: invert(90%);
    filter: invert(100%);
}
.side-bar-btn:hover .right-arrow-icon{
    mix-blend-mode: plus-lighter;
    -webkit-filter: invert(90%);
    filter: invert(100%);
}
.side-bar-btn.active .right-arrow-icon{
    mix-blend-mode: plus-lighter;
    -webkit-filter: invert(90%);
    filter: invert(100%);
}
.dark-mode .side-bar-btn:hover img{
z-index:99;
}

/* .dark-mode .side-bar-icon path {
    fill: #56687b!important;
} */

/* .dark-mode path:hover{
    fill:#fff ;
} */

.dark-mode .side-bar-mobile {
    background-color: #1f2224 !important;
}

.side-bar-btn:hover p {
    color: #fff !important;

}
.side-bar-btn.active p {
    color: #fff !important;

}

.side-bar-icon {
    display: unset;
}

.dark-mode .santa-name {
    color: white !important;
}

.dark-mode .search-bar-dark {
    background-color: #1a1e21 !important;
}

.css-cveggr-MuiListItemIcon-root {
    min-width: none !important;
}

/* .applogo{
    justify-content: space-between;
    width:100% !important;
} /
/ .santa-logo{
    width:150px;
    margin-left:40px;
}
*/
.santa-logo-icons{
    width:20px;
    margin-left:-3px;
}
.applogo {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.hamburger {
    margin-left: 20px !important;
 
    font-size: 32px;
    
}

.santa-logo {
    width: 100%;
    margin-left: 40px !important;
}

.MuiContainer-root {
    padding: 0px !important;
}
.dark-mode .dialogheading {
    color:white !important;
}
.dark-mode .dialogname{
    color:white !important;
}
/* 
.side-bar-btn:hover path {
    fill: #FFFF;
} /
/ 
.side-bar-btn.active path {
    fill: #FFFF;
} */
@media only screen and (min-width:320px) and (max-width:599px) {
    .search-icon {
        position: absolute !important;
        top: 40px !important;
        left: 22px !important;
    }
}
@media only screen and (min-width:320px) and (max-width:465px){
    .login-popup{
        width:250px !important;
        padding:20px !important;
    }

.signup-popup{
    width:253px !important;
    padding:20px !important;
}
.button1{
    width:180px !important;
}
}
@media only screen and (min-width:466px){
    .button1{
        width:150px !important;
    }
}

/* .side-bar-btn:hover .right-arrow-icon{
    display:block;
color:'white'
}/
/ .side-bar-btn:hover .right-arrow-icon{
    display:'block';

}  
.right-arrow-icon{
    display:none;
} */

.side-bar-btn .right-arrow-icon {
    display: none;
}

.side-bar-btn:hover .right-arrow-icon {
    display: block !important;
}
.side-bar-btn.active .right-arrow-icon {
    display: block !important;
}

.side-bar-btn {
    padding-right: 0px !important;
}
 /* .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
    width: 160px !important;

}   */

.side-bar-btn{
    width: 160px !important;
}

.background-home {
    display: flex;
    padding: 10px;
    background-color: rgb(240, 243, 247);
}

.dark-mode .background-home {
    background-color: #191d1f;
}

.dark-mode .main-list-items {
    background-color: rgb(31, 34, 36) !important;
}

.dark-mode .home-page-category {
    background-color: #1f2224 !important;
    border-radius: 10px;
}

.App {
    text-align: center;
}

.dark-mode .data-name {
    color: #FFFFFF !important;

}

.dark-mode .data-desc {
    color: #EDF0F4 !important;

}

.dark-mode .home-parent {
    color: white !important;

}

.dark-mode .open-name {
    color: white !important;

}

.dark-mode .section1-comp5 hr {
    border-color: #2F3539 !important;
}

.dark-mode .polygon-desc {
    color: #95A5B5 !important;
}

.dark-mode .side-bar-name {
    color: white !important;
}

.dark-mode .app-bar {
    background-color: #2F3539;
}
.dark-mode .chipbtn {
    border-radius: 8px !important;
    height: 22px !important;
    background-color: #2F3539;
 }
.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.login-popup {
    padding: 5%;
    border-radius: 20px;
    width: 400px;
}
.login-popup .MuiInputBase-colorPrimary{
    height: 40px !important;
}
.signup-popup .MuiInputBase-colorPrimary{
    height: 40px !important;
}

.login-popup .css-wb57ya-MuiFormControl-root-MuiTextField-root{
    margin-bottom: 12px !important;
}
.signup-popup .css-wb57ya-MuiFormControl-root-MuiTextField-root{
    margin-bottom: 12px !important;
}
.login-popup label{
    line-height: unset !important;
    font-size: 13px !important;
    top:-2px !important;
}
.signup-popup label{
    line-height: unset !important;
    font-size: 13px !important;
    top:-2px !important;
}
.signup-popup {
    padding: 5%;
    border-radius: 20px;
    width: 400px;
}

.button1 {
    border: none;
    border-radius: 4px;
    color: #FFFFFF;
    height: 32px;
    background-color: black !important;
    margin-top: 10px !important;
    margin:auto !important;
    cursor:pointer;

}
.login-bottom{
    display:flex;
  justify-content: center;
  margin-top:10px;
  align-items:baseline;
}
.label-name{
    display:flex;
  justify-content: center;
}
.error{
    color:rgb(247, 16, 16);
    font-size:13px;
    margin-bottom:10px;
 }
 .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    font-size:18px !important ;
 }
 .main-list-items{
    overflow-y:scroll !important;
    height:100vh;
    scrollbar-width:thin !important;
    overflow-x: hidden;
    padding:0px !important;
 
 } 
 .main-list-items::-webkit-scrollbar {
    width: 0px;
}
input{
width:100%;
padding: 12px 45px;
display: inline-block;
border: 1px solid #ccc;
border-radius: 4px;
box-sizing: border-box;
}
/* .input-field{
position:relative;
}
.input-icon{
position:absolute;
top:48px;
right:13px;
cursor:pointer;
} */
.label-form{
    font-weight: bold;
    font-size:20px;

}
.input:focus{
    outline-color:#9B1F1F;
}
.dark-mode .css-419ky9 {
    background-color: black !important;
}

/* .MuiDrawer-root.MuiDrawer-docked.css-kvx9nv-MuiDrawer-docked{
 
    position: sticky;
    top: 0;
    height: 100vh !important;
} */
.side-search-bar{
    position:"relative";
}
.search-icon {
    position: absolute;
    top: 40px;
    left: 23px;
}

.drawer .MuiPaper-root{
    border-radius: 10px;
}
/* .active{
    background: transparent linear-gradient(90deg, #E54949 0%, #E24D4D 4%, #E54949 30%, #CB4444 61%, #9B1F1F 100%) 0% 0% no-repeat padding-box;
     width: 100% !important; 
    border-radius: 8px !important; 
    width:200px !important;
} */
.side-bar-name.active{
    color:white !important;
}
/* .side-bar-name{
    white-space: break-spaces;

} */
.dark-mode .gridcomp{
    background-color: #1f2224 !important;
}
.dark-mode .chip-btn{

    height:22px;
    border-radius:8px;

}
.dark-mode .ratingheading2{
    color:white;
}
.dark-mode .website{
    color:white !important;
}
.dark-mode .twitter{
    color:white !important;
}

.dark-mode .show-btn{
    background-color: #322727 !important;
    color:white !important;
}
.dark-mode .onclick-hover:hover{
    background-color: #383737;
}

.skeleton-icon{
    width:20px;
    height:20px;
    border-radius: 100%;
}

@media (min-width:320px) and (max-width:600px){


    .background-home {
        padding: 10px;
        background-color: rgb(240, 243, 247);
    }

}
.iconbutton-hamburger span{
    width:0px;
}
.app-bar{
    position: sticky;
    background-color: #f0f3f7;
    top: 0;
    z-index: 99;
}
.dark-mode .hamburger svg{
    color:white !important;
   
  
}
.hamburger svg{
    padding-top:5px !important;
}
.dark-mode .santa-logo-name{
    color:white !important;
  }
  .applogo{
    display:flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-left:21px;
    margin-top:5px;

  }
  .iconbutton-hamburger{
padding-top:0px !important;
padding-bottom: 0px !important;
  }
 /* .logo-details{
    position:absolute !important;
   bottom:0px !important;
  }  */
  .inputField{
    position:relative;
    background: #F0F3F7 0% 0% no-repeat padding-box;
    height:38px;
    border-radius: 10px;
    border:none;

  }
  .inputField::placeholder{
    font-size:12px;
  
  }
  .searchIcon{
    position:absolute;
    top:22px;
    left:25px;
    cursor:pointer
  }
  .SearchFunc{
    padding:10px;
   
  }
  .logo-box{
    /* position:relative !important; */
    padding:10px 0px !important;
    margin-top:0px !important;
  }
  .onclick-hover{
    width:100% !important;
    max-width: 600px !important;
  }
  .category_btn{
    padding:10px;
    margin-right:10px;
    border-radius:8px;
    color:white;
    border:none;
    background-color: #f17a7a;
    margin-bottom:30px;
  }
  .category_list{
    border-top:1px solid RGB(213 215 217);
    padding:10px;
  }
  
  .category_list .active{
    background-color: #c52020 !important;
    color:white !important;
    width:auto !important;
    
  }

  .list-item{
    padding:6px 25px;
    border:none;
   background-color: white;
    border-radius:10px;
    color: #95a5b5;
    cursor:pointer;
    font-size:14px;
   
  }
  .list-item.active{
    background-color:#95a5b5 !important;
  }
  .protocol-btn{
    width:200px !important;
    background-color: #f0f3f7 !important;
    border-radius:10px !important;
    color:black !important;
    font-size:14px !important;
    font-weight:bold !important;
    padding:9px !important;
    font-family: 'Poppins' !important;
    text-transform: none !important;
    justify-content: space-between !important;
    
  }
.protocol{
    padding:10px 0px;
}
.table-top{
   width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
}
.table{
    border:1px solid RGB(213 215 217);
    border-left:none;
    border-right:none;
    width:100%;
    border-collapse: collapse;
}

.table-data{
    text-align: left;
    padding:10px 0px;
   
}
.table-data-index{
    text-align: center;
    padding:10px 0px;
}
th{
    padding:10px 0px;
    font-size:15px;
}
.name-profile{
    display: flex;
    align-items: center;
}
.star-rating-icon {
    font-size: 1rem !important;
  }

  .networks{
    display:flex;
    align-items: center;
    gap:6px;
  }
  .networks p{
    font-size:11px;
  }
  .network_div{
    display: flex;
    align-items: center;
    gap:3px;
    
  }
 .network_dot{
    background-color: black;
    height:3px;
    width:3px;
    border-radius:100%;
 }
  .table-row:hover{
       background-color:#f5f5f5;
       cursor:pointer;
  }
.tooltip_container{
    padding:10px;
    width:200px;
    border-radius:30px !important;
}
.tooltip_container img{
    mix-blend-mode: luminosity !important;
    -webkit-filter: invert(90%) !important;
    filter: invert(300%) !important;
}
.cont-para{
    font-size:12px;
}
.tooltip_div{
    display: flex;
    gap:8px;
    margin-top:10px;
}
.styled_menu .MuiPaper-root {
    top:109px !important;
    left:256px !important;
    margin-top:0px !important;
    background-color: #f0f3f7 !important;
    width:50px !important;
    border-radius: 10px !important;
    min-width:200px !important;
    
}
.network_name{
    font-size:14px;
    color:#6b7a88;
    margin-left:10px;
}
.networks img{
    mix-blend-mode: luminosity !important;
    -webkit-filter: invert(90%) !important;
    filter: invert(20%) !important;
}
.network_dropdown img{
    mix-blend-mode: luminosity !important;
    -webkit-filter: invert(90%) !important;
    filter: invert(30%) !important;
}
.main-class{
    width:calc(100% - 230px);
}