.banner {
    width: 100%;
    border-radius: 10px;
}
.data-desc{
    overflow:hidden;
    /* white-space:nowrap; */
    text-overflow:ellipsis;
     width:100%; 
}
.banner-img{
    width:100% !important;
    max-width: 1165px !important;
    max-height: 409px !important;
    height:100% !important;
    

   
}

