body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x:hidden;
}

code {
  font-family:'Poppins',sans-serif ,
    monospace !important;
    font-size: 20px;
    -webkit-filter: blur(0.000001px) !important;
    -webkit-font-smoothing: antialiased !important;
}
