
.search-box{
 
    display:flex;
    align-items:center;
    gap:10px;
    margin:10px 20px;
}   
.category_list_view{
    padding:10px 0px;
  }
  
  .category_list_view .active{
    background-color: #95a5b5 !important;
    color:white !important;
    width:auto !important;
    
  }
  .protocol{
    margin-top:10px;
  }

 .css-1aquho2-MuiTabs-indicator{
  background-color: white !important;
 }
 