
.metamask-btn1 button{
    border: none;
   
    cursor: pointer;
    color: #FFFFFF;
    padding: 7px 48px;
    text-decoration: none;
    background-color: black !important;
  }
  .metamask-btn2 button{
    width:100%;
    margin:0px 13px;
    border: none;

    cursor: pointer;
    color: #FFFFFF;
    padding: 7px 48px;
    text-decoration: none;
    background-color: black !important;
  }
  .metamask-btn1{
    display:flex;
    justify-content: center;
    margin-top:20px;
   
  }
  .metamask-btn2{
    display:flex;
    justify-content: center;
    margin:20px 0px;
  }
 .web3modal-provider-wrapper{
    padding:0px !important;
    width:33.33% !important;
    border: none !important;
  }
  .web3modal-provider-name{
    font-size:12px !important;
  }
  .web3modal-provider-description{
   display:none !important;
  }
  .web3modal-provider-container{
    border-radius: 4px !important;
    padding:10px 20px !important;
   margin:8px !important;
    background-color: #FFFFFF !important;
   width:130px !important;
    /* display:flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important; */
    gap:8px !important;
  }
  .web3modal-modal-card{
    border-radius: 0px !important;
max-width: 500px !important;
  display:flex !important;
  flex-wrap:wrap !important;
  background-color:rgb(224, 224, 224) !important;
    /* display:flex !important;
    flex-direction: column !important; */
  } 
  .fqonLZ{
    width:100px !important;
} 
.provider_address{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width:100px; 
  margin:0px auto;
}